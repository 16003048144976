@import '../../../styles/color';
@import '../../../styles/responsive';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 16px 80px;
}

.article-summary-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 1040px;
  margin-top: 32px;
}
